import React from 'react';
import Seo from '../components/Seo';
import styled from 'styled-components';
import ButtonLink, { ButtonColor } from '../components/ButtonLink';
import JoinTheCloudSection from '../homeSections/JoinTheCloudSection';
import MarginContainer from '../components/MarginContainer';
import PageHeading, { ActionButtonsContainer } from '../components/PageHeading';
import Paragraph from '../components/Paragraph';
import Heading2 from '../components/pageElements/Heading2';
import PageContentWrapper from '../components/pageElements/PageContentWrapper';
import SmallLineDots from '../images/backgrounds/SmallLineDots.svg';
import PricingPlans from '../homeSections/PricingPlans';
import { graphql, useStaticQuery } from 'gatsby';
import MultilineText from '../components/MultiLineText';
import useCommonTitles from '../components/useCommonTitles';

const BackgroundLineDotsLeft = styled(SmallLineDots)`
  position: absolute;
  right: 20px;
  bottom: -10px;
`;

const BackgroundLineDotsRight = styled(SmallLineDots)`
  position: absolute;
  left: 20px;
  bottom: -10px;
`;

function ServicesPage() {
  const { getStarted } = useCommonTitles();
  const {
    heroSectionTitle,
    heroSectionDescription,
    title,
    subtitle,
  } = useStaticQuery(graphql`
    {
      servicesYaml {
        heroSectionTitle
        heroSectionDescription
        title
        subtitle
      }
    }
  `).servicesYaml;
  return (
    <>
      <Seo title="Services" />
      <PageHeading title={heroSectionTitle} subtitle="Services">
        <div>
          <Paragraph $inverted>{heroSectionDescription}</Paragraph>
          <ActionButtonsContainer>
            <ButtonLink color={ButtonColor.Secondary} href="/console/signup">
              {getStarted}
            </ButtonLink>
          </ActionButtonsContainer>
        </div>
      </PageHeading>
      <PageContentWrapper>
        <BackgroundLineDotsLeft />
        <BackgroundLineDotsRight />
        <MarginContainer>
          <Heading2>
            <MultilineText text={title} />
          </Heading2>
          <Paragraph>{subtitle}</Paragraph>
          <PricingPlans />
        </MarginContainer>
      </PageContentWrapper>
      <JoinTheCloudSection/>
    </>
  );
}

export default ServicesPage;
