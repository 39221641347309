import React from 'react';
import styled from 'styled-components';
import { device } from '../utils/breakpoints';
import ButtonLink, { ButtonColor } from '../components/ButtonLink';
import Checkmark from '../images/Checkmark.svg';
import MarginContainer from '../components/MarginContainer';
import { graphql, useStaticQuery } from 'gatsby';
import useCommonTitles from '../components/useCommonTitles';

const ProductsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 68px;

  @media ${device.desktop} {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
`;

const Product = styled.div`
  flex: 1;
  text-align: center;
  padding: 64px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 25px;
  max-width: 600px;
  border: 4px solid
    ${(props) => (props.$highlighted ? props.theme.primary : props.theme.white)};
  box-shadow: 0px -2px 53px rgba(20, 48, 189, 0.03),
    0px -0.835552px 22.1421px rgba(20, 48, 189, 0.0215656),
    0px -0.446726px 11.8382px rgba(20, 48, 189, 0.0178832),
    0px -0.250431px 6.63642px rgba(20, 48, 189, 0.015),
    0px -0.133002px 3.52455px rgba(20, 48, 189, 0.0121168),
    0px -0.0553451px 1.46665px rgba(20, 48, 189, 0.00843437);

  &:not(:last-child) {
    margin-right: 56px;

    @media ${device.desktop} {
      margin-right: 0;
    }
  }

  @media ${device.desktop} {
    margin-right: 0;
    margin-top: 16px;
    padding: 20px;
    width: 100%;
  }
`;

const Perks = styled.div`
  text-align: left;
  padding: 8px;
`;

const PerkWrapper = styled.div`
  font-family: 'Open Sans', serif;
  font-size: 16px;
  padding: 8px;
  display: flex;
  align-items: center;

  & > svg {
    margin-right: 14px;
  }
`;

const StyledH3 = styled.h3`
  font-family: Poppins, sans;
  color: ${(props) => props.theme.primary};
  font-size: 32px;
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AlignCenter = styled.div`
  display: flex;
  justify-content: center;
`;

const ButtonWithTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > span {
    color: ${(props) => props.theme.primary};
    text-transform: uppercase;
    text-align: center;
    font-size: 12px;
  }
`;

export default function PricingPlans() {
  const { getFreeAccount, noCreditCardRequired } = useCommonTitles();

  const { freeTierPerks, premiumTierPerks } = useStaticQuery(graphql`
    {
      servicesYaml {
        freeTierPerks
        premiumTierPerks
      }
    }
  `).servicesYaml;
  return (
    <MarginContainer>
      <ProductsWrapper>
        <Product>
          <div>
            <StyledH3>Free Tier</StyledH3>

            <Perks>
              {freeTierPerks.map((p) => (
                <Perk key={p}>{p}</Perk>
              ))}
            </Perks>
          </div>
          <AlignCenter>
            <ButtonLink
              color={ButtonColor.Primary}
              $fitContent
              href="/console/signup"
            >
              {getFreeAccount}
            </ButtonLink>
          </AlignCenter>
        </Product>
        <Product $highlighted>
          <div>
            <StyledH3>Premium Tier</StyledH3>

            <Perks>
              {premiumTierPerks.map((p) => (
                <Perk key={p}>{p}</Perk>
              ))}
            </Perks>
          </div>
          <AlignCenter>
            <ButtonWithTextWrapper>
              <ButtonLink
                color={ButtonColor.Primary}
                $fitContent
                href="/console/signup"
              >
                {getFreeAccount}
              </ButtonLink>
              <span>{noCreditCardRequired}</span>
            </ButtonWithTextWrapper>
          </AlignCenter>
        </Product>
      </ProductsWrapper>
    </MarginContainer>
  );
}

function Perk({ children }) {
  return (
    <PerkWrapper>
      <Checkmark />
      {children}
    </PerkWrapper>
  );
}
